.shareOverlay {
    @apply
    fixed
    top-0
    left-0
    w-screen
    h-screen
    flex
    justify-center
    items-start
    sm:items-center
    bg-black/75
    z-50;
}

.shareOverlay .inner-overlay {
    @apply
    relative
    max-w-[90%]
    mt-20
    sm:mt-0
    h-[65vh]
    md:h-auto
    p-8
    flex flex-col justify-center items-center
    bg-secondary-950/90
    z-[9999]
    rounded-2xl;
}

.shareOverlay .inner-overlay.animate {
    animation: popUp .2s ease-in;
}

.shareOverlay .inner-overlay .qr {
    @apply bg-white rounded-2xl p-4 mb-3 max-h-[50%] min-w-32 min-h-32;
}

.shareOverlay .inner-overlay .share-button-row {
    @apply flex;
}

.shareOverlay .inner-overlay .share-button-row .copy-to-clipboard {
    @apply
    h-12
    w-12
    m-0
    shrink-0
    fill-accent;
}

.shareOverlay .inner-overlay .share-button-row .copy-to-clipboard.done {
    @apply fill-green-600;
}

@keyframes popUp {
    0% { transform: scale(1%); opacity: 0; }
    60% { transform: scale(100%); opacity: 1; }
    80% { transform: scale(90%); }
    100% { transform: scale(100%); }
}