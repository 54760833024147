.desktop {
    @apply
    container
    flex
    justify-between
    bg-secondary-950/50
    py-4
    px-4
    rounded-xl
    mt-auto
    backdrop-blur-sm
    text-sm
    border-secondary-950
    border-2;
}

.mobile {
    @apply
    border-0
    fixed
    w-full
    h-14
    bottom-0
    flex
    justify-between
    rounded-none
    bg-secondary-950
    mt-auto
    z-[9999];

    & a {
        @apply
        !flex
        justify-center
        items-center
        w-1/3
        h-full;

        &:not(:last-child) {
            @apply
            border-r
            border-r-black;
        }

        & svg, & img {
            @apply
            h-8
            w-8
            text-primary-950
            fill-white;
        }
    }
}