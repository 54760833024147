.symbol {
    font-weight: 600;
    font-size: 2rem;
    vertical-align: middle;
    color: blue;
}

.TTT-board {
    @apply rounded-2xl bg-secondary-950/30 backdrop-blur-sm p-10 border-collapse my-5;
    & td {
        @apply border-8
        border-secondary-900
        rounded-full
        empty:hover:blur-xl
        empty:hover:cursor-pointer
        empty:hover:bg-accent/50
        hover:cursor-not-allowed
        w-20
        h-20
        sm:w-28
        sm:h-28;
        text-align: center;
        vertical-align: center;
        font-size: 3rem;
    }
    &.disabled {
        @apply bg-gray-700/50 blur-sm;
        & td {
            @apply border-gray-700 hover:cursor-not-allowed hover:bg-none empty:hover:bg-transparent hover:blur-0;
        }
    }
}


.TTT-board td:nth-child(2n+1) {
    border-width: 0 0 8px 0;
}

.TTT-board tr:first-child td:nth-child(2) {
    border-top-width: 0;
}

.TTT-board tr:nth-child(3) td {
    border-bottom-width: 0;
}

.playing-as {
    position: absolute;
    top: 0;
    width: 100vw;
    text-align: center;
}
