@import "assets/css/custom.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Orbitron';
        src: url('assets/fonts/Orbitron/Orbitron-VariableFont_wght.ttf');
    }
}

