html {
    background: #000;
}

body {
    background-image: url("../img/hexagons_medium_dark.jpg");
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    background-blend-mode: overlay;
    color: white;
}

footer a.active {
    @apply shadow-inner shadow-black !important;
    & svg, & img {
        @apply fill-accent scale-95 translate-x-[2px] translate-y-[2px] !important;
    }
}

.react-responsive-3d-carousel__carousel__item {
    --tw-backdrop-blur: blur(4px);
    -webkit-backdrop-filter: var(--tw-backdrop-blur);
    backdrop-filter: var(--tw-backdrop-blur);
}

.pending-games-list {
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-y: scroll;
}

.pending-games-list::-webkit-scrollbar {
    display: none;
}

@keyframes fromLeft {
    0%, 40% { transform: translateX(-50vw); }
    90%, 100% { transform: translateX(0); }
}

@keyframes fromRight {
    0%, 50% { transform: translateX(50vw); }
    100% { transform: translateX(0); }
}

@keyframes fromAbove {
    0% { transform: translatey(-80vh); }
    70%, 100% { transform: translatey(0); }
}

.replay {
    animation: fromLeft 0.5s ease-in-out;
}

.rematch {
    animation: fromRight 0.6s ease-in-out;
}

.game-over-msg {
    animation: fromAbove 0.5s ease-in;
}
