.connect-four-container {
    @apply block relative backdrop-blur-sm mt-4 border-secondary-900/80 border-8 rounded-2xl w-full md:w-3/4 lg:w-3/5 xl:w-2/4;
}

.connect-four-board {
    @apply relative border-collapse bg-primary-950/70 w-full;
}

.connect-four-board-top-layer {
    @apply absolute border-collapse overflow-hidden top-0 left-0 w-full;
}

.connect-four-board-top-layer td, .connect-four-board td {
    @apply opacity-90 relative text-[0];
    width: calc(100% / 7);
    padding-top: calc(100% / 7);
}

.connect-four-board-top-layer td {
    @apply overflow-hidden;
    &:after {
        box-shadow: #0c4a6e 0 0 0 100px;
        @apply
        absolute
        block
        content-['']
        bg-transparent
        border-[6px]
        border-t-secondary-950
        border-b-secondary-950/80
        border-l-secondary-950
        border-r-secondary-950/80
        opacity-75
        top-1/2
        left-1/2
        -translate-x-1/2
        -translate-y-1/2
        box-content
        w-2/3
        aspect-square
        rounded-full;
    }
}

.connect-four-board-top-layer td:hover {
    cursor: pointer;
}

.disabled.connect-four-board-top-layer td:hover {
    cursor: not-allowed;
}

.highlight-column:after {
    box-shadow: #FBCE27 0 0 0 100px !important;
}

.disabled .highlight-column:after {
    box-shadow: #555 0 0 0 100px !important;
}

.connect-four-board .circle {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-5/6 h-5/6 rounded-full;
    animation: drop .5s ease-in;
}

.opponentSlug .circle {
    @apply w-12 h-12 rounded-full border-4;
}

@keyframes drop {
    0%, 20% { transform: translateX(-50%) translateY(-1000px); }
    80% { transform: translateX(-50%) translateY(-50%); }
    93% { transform: translateX(-50%) translateY(-70%); }
    100% { transform: translateX(-50%) translateY(-50%); }
}

.circle.red {
    @apply bg-red-600 border-t-red-400 border-l-red-400 border-b-red-800 border-r-red-800;
}

.circle.yellow {
    @apply bg-amber-600 border-t-amber-400 border-l-amber-400 border-b-amber-800 border-r-amber-800;
}

.connect-four-board .symbol,
.connect-four-board .symbol {
    display: none !important;
}
